import React from 'react'
import BackgroundSVG from './BackgroundSVG'
import Navbar from './Navbar'
import HeroSection from './HeroSection'
import Pricing from './Pricing'

function LandingPage() {
  return (
    <div class='w-full min-h-screen flex flex-col'>
        <div class='w-full min-h-screen'>
            <Navbar/>
            <HeroSection/>
        </div>
        <Pricing/>
    </div>
  )
}

export default LandingPage
import React from 'react'
import pic from '../assets/Background PNG.png'


function HeroSection() {

    function RedirectPricing()
    {
        window.location.href='#Prices'
    }

    function RedirectSignup()
    {
        window.location.replace('/signup')
    }

  return (
    <div class='w-full min-h-[100vh] md:flex-row flex-col flex items-center justify-between py-16 md:px-16 px-8 bg-white space-y-20'>
        <div class='md:w-[50%] flex flex-col space-y-14 mt-10 it4ems-center text-white'>
            <div class='flex flex-col space-y-10'>
                <text class=' md:text-start text-center lg:text-[6.5vh] text-4xl font-font1 font-bold tracking-wide leading-tight text-[#03296b]'>Tenha o <text class='text-red-500'>Ingles</text> perfeito com a ajuda da inteligencia Artificial</text>
                <text class=' md:text-start text-center text-[#03296b] lg:text-[4.5vh] text-[3vh] font-font1 font-semibold tracking-wide leading-tight'>Kassoma, O Seu Professor Virtual 24/7</text>
            </div>
            <div class='w-full flex flex-col items-center space-y-6'>
                <text class='font-sans font-light text-[#03296b] lg:text-lg sm:text-base text-sm md:w-11/12 w-full text-center'>APRENDA GRATUITAMENTE HOJE</text>
                <button class='w-[45%] py-4 rounded-full text-white bg-[#fcc502] ' onClick={RedirectSignup} >Comecar a aperender</button>
                <text class='font-sans font-medium text-[#03296b] lg:text-lg sm:text-base text-sm md:w-11/12 w-full text-center'>#1 AI de Africa</text>
            </div>
        </div>
        <div class='md:w-[50%] w-full h-full flex justify-center items-center'>
            <img src={pic} class='w-4/5'></img>
        </div>
    </div>
  )
}

export default HeroSection
import React, { useState } from 'react'
import {BsVectorPen,BsBookHalf} from 'react-icons/bs'
import {RiVoiceprintFill} from 'react-icons/ri'
import {BiSolidSend,BiMenu} from 'react-icons/bi'
import UserChat from './UserChat'
import BotChat from './BotChat'
import Sidebar2 from './Sidebar2'

function Dashboard() {

    const [Input,setInput]=useState('')
    const [TotalMessage,setTotalMessage]=useState([])
    const [SidebarCheck,setSidebarCheck]=useState(false)

    function RedirectLogin()
    {
        window.location.replace('/login')
    }

    function HandleInput(e)
    {
        setInput(e.target.value)
    }

    function StartChat()
    {
        if(Input.length>0)
        {
            setTotalMessage(obj=>[...obj,{role:'user',message:Input}])
            setTotalMessage(obj=>[...obj,{role:'system',message:'Certamente! Eu sou um modelo de linguagem AI desenvolvido pela OpenAI. Meu propósito é ajudar e me comunicar com as pessoas por meio de interações baseadas em texto. Posso responder a perguntas, fornecer informações e realizar tarefas dentro de minhas habilidades programadas. Como assistente de IA, pretendo ser um recurso útil e confiável para usuários que buscam assistência em várias tarefas ou consultas. Você tem alguma dúvida ou solicitação com a qual posso ajudá-lo?'}])
            setInput('')
        }
    }

    function HandleSideBar()
    {
        setSidebarCheck(!SidebarCheck)
    }

  return (
    <div class='w-full h-screen flex'>

        {
            SidebarCheck==true?
            <Sidebar2/>
            :
            <></>
        }

        <div class='lg:w-[19%] md:w-[24%] h-full bg-[#03296b]/5 backdrop-blur-md md:flex hidden flex-col py-10 lg:px-4 md:px-4 items-start justify-between'>
            <text class='text-3xl font-font1 font-bold '>Kassoma IA</text>
            
            <div class='w-full flex flex-col space-y-1'>
                
                <button class='flex items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#fcc502]'>
                    <BsVectorPen size='1.5rem' color='#03296b'/>
                    <text class='text-xl'>Aprender</text>
                </button>

                <button class='flex items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#fcc502]'>
                    <BsBookHalf size='1.5rem' color='#03296b'/>
                    <text class='text-xl'>Lição</text>
                </button>

            </div>

            <div class='w-full px-3'>
                <button class='bg-[#03296b] w-full py-3 font-font1 text-lg text-white rounded-lg ' onClick={RedirectLogin}>Log out</button>
            </div>
        </div>

        <div class='w-fit md:hidden flex py-3 z-50' onClick={HandleSideBar}>
            <BiMenu size='2.5rem'/>
        </div>


        <div class='md:w-[80%] w-full h-full md:p-10 p-4 flex flex-col justify-between items-center space-y-2'>
            <div class='lg:w-[65%] md:w-[80%] w-full flex flex-col overflow-y-scroll'>
                {
                    TotalMessage.map((obj)=>
                        obj.role=='user'?
                            <UserChat content={obj.message}/>
                        :
                            <BotChat content={obj.message}/>
                    )
                }
            </div>
            <div class='lg:w-[65%] md:w-[80%] w-full flex flex-col border-[2px] rounded-lg '>
                <input class='w-full rounded-lg py-3 px-3 outline-none text-base font-font2' placeholder='Ask or search anything' onChange={HandleInput} value={Input}></input>
                <div class='w-full px-2 py-2 bg-gray-100 flex justify-between'>
                    <button class='py-2 px-2 flex items-center hover:bg-[#fcc502] rounded-lg'>
                        <RiVoiceprintFill size='1.2rem'/> 
                        <text class='sm:text-sm text-xs ml-3 font-font2'>Reconhecimento de voz</text>
                    </button>
                    <button onClick={StartChat} class={Input.length>0?'flex p-2 justify-center items-center border-[2px] bg-[#fcc502] rounded-full':'flex p-2 justify-center items-center border-[2px] bg-[#fcc502]/10 rounded-full'}>
                        <BiSolidSend size='1.2rem' color='white'/>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard
import React, { useState } from 'react'
import {AiOutlineArrowDown} from 'react-icons/ai'
import {US,ES,FR} from 'country-flag-icons/react/3x2'

function Sidebar(props) {

    const [LangCheck,setLangCheck]=useState(false)

    function OpenLanguages()
    {
        setLangCheck(!LangCheck)
    }

  return (
    <div class='md:hidden w-full flex flex-col py-5 px-8 text-black/60 font-font2 text-lg bg-white space-y-14'>
        <div class='w-full space-y-4 flex flex-col items-start'>
            <button class='hover:text-black'>Home</button>
            {
                props.check!=1 && props.check!=2?
                <button class='hover:text-black'>Preço</button>
                :
                <></>
            }
            <button class='hover:text-black flex items-center' onClick={OpenLanguages}>
                <text>Seu idioma</text>
                <AiOutlineArrowDown class='ml-2'/>
            </button>
            {
                LangCheck==true?
                    <div class='w-full flex flex-col bg-[#03296b]/5 py-5 px-8 space-y-2'>
                        <button class='flex items-center text-start hover:text-black'>
                            <US class='w-6 mr-3'/>
                            <text>English</text>
                        </button>
                        <button class='flex items-center text-start hover:text-black'>
                            <ES class='w-6 mr-3'/>
                            <text>Spanish</text>
                        </button>
                        <button class='flex items-center text-start hover:text-black'>
                            <FR class='w-6 mr-3'/>
                            <text>French</text>
                        </button>
                    </div>
                :
                    <></>
            }
        </div>

        <div class='w-full flex flex-col space-y-4'>
            {
                props.check!=2?
                <button class='py-2 bg-[#fcc502] text-white rounded-lg'>Sign in</button>
                :
                <></>
            }
            {
                props.check!=1?
                <button class='py-2 rounded-lg border-[2px] border-[#fcc502] text-[#fcc502]'>Sign up</button>
                :
                <></>
            }
        </div>
    </div>
  )
}

export default Sidebar
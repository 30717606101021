import React from 'react'
import { Simulate } from 'react-dom/test-utils'
import SinglePrice from './SinglePrice'
import BackgroundSVG from './BackgroundSVG'

function Pricing() {



  return (
    <div id='Prices' class='min-h-screen'>
        <div class='w-full min-h-screen flex flex-col sm:px-16 px-8 pb-4 pt-24 justify-between space-y-14 items-center'>
                <text class='text-5xl font-font1 font-bold'>Planos e Preços</text>
            <div class='w-full min-h-full flex flex-row flex-wrap justify-center items-center'>
                <SinglePrice />
                <SinglePrice check={1}/>
                <SinglePrice/>
            </div>
        </div>
    </div>
  )
}

export default Pricing
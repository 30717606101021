import React from 'react'
import {BsVectorPen,BsBookHalf} from 'react-icons/bs'

function Sidebar2() {

    function RedirectLogin()
    {
        window.location.replace('/login')
    }

  return (
    <div class='w-[50%] h-full fixed bg-[#03296b]/5 backdrop-blur-md flex md:hidden flex-col pb-10 pt-20 lg:px-4 md:px-4 items-start justify-between'>
            
            <div class='w-full flex flex-col space-y-1'>
                
                <button class='flex items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#fcc502]'>
                    <BsVectorPen size='1.5rem' color='#03296b'/>
                    <text class='text-xl'>Aprender</text>
                </button>

                <button class='flex items-center font-font2 space-x-5 py-3 px-3 rounded-full hover:bg-[#fcc502]'>
                    <BsBookHalf size='1.5rem' color='#03296b'/>
                    <text class='text-xl'>Lição</text>
                </button>

            </div>

            <div class='w-full px-3'>
                <button class='bg-[#03296b] w-full py-3 font-font1 text-lg text-white rounded-lg ' onClick={RedirectLogin}>Log out</button>
            </div>
    </div>
  )
}

export default Sidebar2
import React from 'react'
import Navbar from './Navbar'

function Login() {

    function RedirectSignup()
    {
        window.location.replace('/signup')
    }

    function RedirectDashboard()
    {
        window.location.replace('/dashboard')
    }

  return (
    <div class='w-full min-h-screen bg-gradient-to-br from-[#fcc502] to-[#03296b]'>
        <Navbar check={2}/>
        <div class='w-full h-full flex justify-center items-center p-6'>
            <div class='lg:w-[37%] md:w-[50%] sm:w-[85%] items-center flex flex-col space-y-14 bg-white rounded-xl py-10 px-14 mt-20'>
                <div class='flex flex-col space-y-5 items-center'>
                    <text class='text-4xl font-font1 font-bold'>Sign in</text>
                    <text class='text-center text-black/50'>Bem-vindo ao Kassoma IA Speech Analyzer! Vamos configurar você!</text>
                </div>
                <div class='w-full flex flex-col space-y-5'>
                    <div class='w-full flex flex-col'>
                        <text class='text-black/70'>Email</text>
                        <input class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='abc@mail.com'></input>
                    </div>
                    <div class='w-full flex flex-col'>
                        <text class='text-black/70'>Password</text>
                        <input class='w-full pt-3 border-b-[2px] outline-none pb-1' placeholder='........' type='password'></input>
                    </div>
                </div>

                <div class='w-full space-y-5 flex flex-col'>
                    <button class='w-full py-3 rounded-xl font-font2 text-lg bg-[#03296b] text-white' onClick={RedirectDashboard}>Sign in</button>
                    <div class='flex items-center space-x-5'>
                        <hr class='w-1/2 border-[1px]'/>
                        <text class='text-black/50'>ou</text>
                        <hr class='w-1/2 border-[1px]'/>
                    </div>
                    <div class='flex w-full justify-center space-x-2'>
                        <text>Don't have an account? </text>
                        <button class='text-[#03296b] underline' onClick={RedirectSignup}>Sign up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login
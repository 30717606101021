import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './Components/Dashboard';
import LandingPage from './Components/LandingPage';
import Login from './Components/Login';
import Signup from './Components/Signup';
import { Fragment } from 'react';

function App() {
  return (
    <div class='w-full min-h-screen'>

      <BrowserRouter>

        <Fragment>

          <Routes>

            <Route path='/login' exact Component={Login}/>
            <Route path='/signup' exact Component={Signup}/>
            <Route path='/dashboard' exact Component={Dashboard}/>
            <Route path='/' exact Component={LandingPage}/>

          </Routes>

        </Fragment>

      </BrowserRouter>

    </div>
  );
}

export default App;
